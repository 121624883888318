import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card } from "shards-react";
import { useTranslation } from "react-i18next";
import Background from '../../images/background-signup.png';
import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [credentials, setCredentials] = useState({ email: "", pwd: "" });
  const [rememberMe, setRM] = useState(false);
  const [msgError, setMsgError] = useState({ msg: "", displayMsg: "hide" });
  const [local, setLocal] = useState(localStorage.getItem("local") || "en");
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // Function to decode JWT token
  const decodeJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      ).join(''));

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return null;
    }
  };

  // Handle form submission for regular login
  const handleSubmit = async (e) => {
    e.preventDefault();
    await LoginApi();
  };

  const LoginApi = async () => {
    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: credentials.pwd, email: credentials.email })
    };

    await fetch(`${process.env.REACT_APP_BASE_URL}/login`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.errors) {
          result.errors.forEach(item => {
            setMsgError({ msg: item.msg, displayMsg: "show" });
          });
        } else if (result.type === "danger") {
          setMsgError({ msg: result.message || "An unexpected error occurred. Please try again later.", displayMsg: "show" });
        } else {
          const data = result.result;
          localStorage.setItem("user", JSON.stringify({
            email: data.email,
            name: data.name,
            id: data.uid,
            role: data.role,
            offer_type: data.offer_type,
            avatar: data.upload_file_name,
            has_command: data.has_command
          }));
          localStorage.setItem("token", JSON.stringify({ token: result.token }));
          localStorage.setItem("refreshToken", result.refreshToken);
          localStorage.setItem("lastLogin", new Date().toISOString());

          if (data.role === "ROLE_ADMIN") {
            history.push("/admin/users");
          } else if (data.role === "ROLE_USER" && data.offer_type === '2') {
            history.push("/");
          } else if (data.role === "ROLE_USER" && data.offer_type === '1') {
            history.push("/Dashboard");
          }
          window.location.reload();
        }
      })
      .catch(error => {
        console.error("Login failed:", error.message);
        setMsgError({ msg: "Login failed. Please try again later.", displayMsg: "show" });
      });
  };

  // Handle language change
  const changeLanguageHandler = async (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
    localStorage.setItem("local", languageValue);
    setLocal(languageValue);
  };

  // Handle Google login success
  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log("Google login response:", credentialResponse); // Debugging
    try {
      const token = credentialResponse.credential;
  
      if (!token) {
        setMsgError({ msg: "Google login failed: Token is missing.", displayMsg: "show" });
        return;
      }
  
      const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken: token })
      };
  
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/google-login`, requestOptions);
      const result = await response.json();
  
      console.log("Received response from backend:", result); // Debugging
  
      if (response.ok) {
        // Store user and token data
        localStorage.setItem("user", JSON.stringify({
          email: result.email,
          name: result.name,
          id: result.userId,
          role: result.role,
          offer_type: result.offer_type,
          avatar: result.picture
        }));
        localStorage.setItem("token", JSON.stringify({ token: result.token }));
        localStorage.setItem("refreshToken", result.refreshToken);
        localStorage.setItem("lastLogin", new Date().toISOString());
  
        // Determine redirection URL
        let redirectUrl = "/";
        if (result.role === "ROLE_ADMIN") {
          redirectUrl = "/admin/users";
        } else if (result.role === "ROLE_USER" && result.offer_type === '1') {
          redirectUrl = "/Dashboard";
        }
  
        console.log("Redirecting to:", redirectUrl); // Debugging
  
        // Redirect to the appropriate page
        history.push(redirectUrl);
      } else {
        console.error("Google login failed:", result.message);
        setMsgError({ msg: result.message || "An unexpected error occurred. Please try again later.", displayMsg: "show" });
      }
    } catch (error) {
      console.error("Google login error:", error);
      setMsgError({ msg: "Login failed. Please try again later.", displayMsg: "show" });
    }
  };
  
  

  
  

  return (
    <Container fluid className="main-content-container p-4">
      <Row className="d-flex justify-content-center align-items-center">
        <Col lg="8" md="12" sm="12">
          <Card className="p-4">
            <Row>
              <Col lg="5" md="12" sm="12" className="border-right" style={{ backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat" }}>
                <div className="pt-5">
                  <div>
                    <img style={{ width: "100%" }} src={require("../../images/Logo smart farm1.jpg")} alt="Smart Farm" />
                  </div>
                  <h4 style={{ fontFamily: "'Satisfy', cursive", color: "#25A6B8", textAlign: "center" }}>{t('agriculture_intelligente')}</h4>
                </div>
              </Col>
              <Col lg="7" md="12" sm="12">
                <div className="col-lg-12 text-right p-0">
                  <select value={local} className="custom-select" style={{ width: 70 }} onChange={changeLanguageHandler}>
                    <option value="en">En</option>
                    <option value="fr">Fr</option>
                    <option value="it">It</option>
                    <option value="ar">Ar</option>
                  </select>
                </div>
                <div className={`mb-0 alert alert-danger fade ${msgError.displayMsg}`} role="alert"><i className="fa fa-info mx-2"></i>{msgError.msg}</div>
                <h3 style={{ textAlign: "center" }}>{t('sign_in')}</h3>
                <div className="mt-2 font-weight-light text-center">
                  <span> {t('have_account')} </span>
                  <Link to="/Register" style={{ color: "#0daaa2" }}>{t('Sign up')}</Link>
                </div>
                <form className="pt-3" onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      style={{ background: "transparent", padding: "7px 0", width: "100%", border: "0", borderBottom: "1px solid #25A6B8", outline: "0", fontSize: "1.1rem" }}
                      placeholder={t('email')}
                      onChange={e => setCredentials({ ...credentials, email: e.target.value })}
                      required
                    />
                  </div>
                  <div className="form-group mb-5">
                    <input
                      type="password"
                      style={{ background: "transparent", padding: "7px 0", width: "100%", border: "0", borderBottom: "1px solid #25A6B8", outline: "0", fontSize: "1.1rem" }}
                      placeholder={t('Password')}
                      onChange={e => setCredentials({ ...credentials, pwd: e.target.value })}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <button
                      type="submit"
                      style={{ color: 'white', backgroundColor: '#27A6B7' }}
                      className="btn btn-block btn-lg font-weight-medium auth-form-btn"
                    >
                      {t('sign_in')}
                    </button>
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={() => console.log('Login Failed')}
                      style={{ marginTop: '10px' }}
                    />
                  </div>
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
