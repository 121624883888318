import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, CardBody, CardHeader, Card, FormSelect } from "shards-react";  // Import des composants Shards-React
import PageTitle from "../components/common/PageTitle";  // Import du composant PageTitle
import { useTranslation } from "react-i18next";  // Import de useTranslation pour la traduction
import SatteliteMap from './SatteliteMap';  // Import du composant SatteliteMap
import moment from 'moment';  // Import de moment pour la manipulation de dates
import api from '../api/api';  // Import du module API personnalisé
import Calendar from 'react-calendar';  // Import du composant Calendar pour la sélection de dates
import 'react-calendar/dist/Calendar.css';  // Styles pour le composant Calendar
import { LinearProgress } from '@mui/material';  // Import de LinearProgress depuis MUI

const SatelliteImages = () => {
  const [coords, setCoords] = useState({  // État pour les coordonnées de la carte
    Latitude: "",
    Longitude: "",
    zoom: "",
    center: [],
    fromAction: false
  });
  const [mapConfig, setMapConfig] = useState({  // Configuration initiale de la carte
    zoom: "",
    center: [],
    fromAction: false,
    draw: {
      polygon: false,
      circle: false,
      rectangle: false,
      polyline: false,
      marker: false,
      circlemarker: false,
    }
  });
  const [dates, setDates] = useState([]);  // État pour les dates disponibles
  const [fields, setFields] = useState([]);  // État pour les champs
  const [farms, setFarms] = useState([]);  // État pour les fermes
  const [selectedField, setSelectedField] = useState([]);  // État pour le champ sélectionné
  const [satellitesImages, setSatellitesImages] = useState([]);  // État pour les images satellites
  const [selectedImages, setSelectedImages] = useState([]);  // État pour les images sélectionnées
  const [dataDisplayed, setDataDisplayed] = useState([]);  // État pour les données affichées
  const [polygonDisplayed, setPolygonDisplayed] = useState([]);  // État pour le polygone affiché

  const [selectedDate, setSelectedDate] = useState(moment().format('D MMM YYYY'));  // Date sélectionnée par défaut

  const mapRef = useRef(null);  // Référence pour la carte

  // Récupère les images satellite pour le champ sélectionné
  const getSatelliteImages = async () => {
    try {
      const fieldId = selectedField[0].Id;
      const apiUrl = `/field/get-sattelite-images/${fieldId}`;
      api.get(apiUrl)
        .then((response) => {
          const fetchedData = response.data.imagesData;
          setSatellitesImages(fetchedData);
        })
        .catch((error) => {
          console.error('Erreur API:', error);
        });

    } catch (error) {
      console.error('Erreur API:', error);
    }
  };

  useEffect(() => {
    // Charge les champs depuis l'API au chargement de la page
    const getDataFields = async () => {
      await api.get('/field/fields').then(res => {
        const newData = res.data.farms;
        setFarms(newData);
        let Fields = [];
        newData.map(item => {
          let fields = item.fields;
          if (fields) {
            fields.map(itemfield => {
              Fields.push({
                title: itemfield.name,
                status: itemfield.status,
                description: itemfield.description,
                Uid: itemfield.uid,
                farm_id: itemfield.farm_id,
                Latitude: itemfield.Latitude,
                Longitude: itemfield.Longitude,
                coordinates: itemfield.coordinates,
                Id: itemfield.id
              });
            });
          }
        });
        setFields(Fields);
      });
    };
    getDataFields();
  }, []);

  useEffect(() => {
    // Charge les images satellite lorsque le champ sélectionné change
    if (selectedField.length > 0) {
      getSatelliteImages();
    }
  }, [selectedField]);

  const { t, i18n } = useTranslation();  // Initialisation de la traduction

  useEffect(() => {
    // Génère un tableau de dates pour les 7 prochains jours
    const next7Days = Array.from({ length: 6 }, (_, index) =>
      moment().subtract(index, 'days').format('D MMM YYYY')
    );

    const ascendingDates = next7Days.reverse();

    setDates(ascendingDates);
  }, []);

  const handleDateClick = (date) => {
    // Gère le clic sur une date dans le calendrier
    setSelectedDate(date);
    const filteredData = satellitesImages.filter(data => moment(data.created_at).format('D MMM YYYY') === date && data.field_id === selectedField[0].Id);
    setSelectedImages(filteredData);
  };

  const getSelectedField = (e) => {
    // Gère la sélection d'un champ depuis la liste déroulante
    const selectedId = e.target.value;
    const selected = fields && fields.filter(field => (
      field.Id == selectedId
    ));
    setSelectedField(selected);
  };

  useEffect(() => {
    // Filtrage des images satellite en fonction de la date et du champ sélectionnés
    const filteredData = satellitesImages.filter(data => {
      return moment(data.created_at).format('D MMM YYYY') === selectedDate && data.field_id === selectedField[0].Id;
    });
    setSelectedImages(filteredData);
  }, [selectedDate, selectedField]);

  const handleClick = (data) => {
    // Gère le clic sur une image satellite
    if (data) {
      setDataDisplayed(data.data);
      setPolygonDisplayed(data.polygon);
    }
  };

  return (
    <Container fluid className="main-content-container px-3 pb-2">
      <Row className="page-header py-2 mb-4">
        <PageTitle subtitle={t('vue_d_ensemble')} title={t('Images Satellites')} className=" mb-1" />
      </Row>

      <Container className="main-content-container p-3 border bg-light rounded">
        <Row>
          <Col lg='8' md="12" sm="12">
            <Row className="pt-4">
              <Col lg='12' md="12" sm="12">
                <SatteliteMap
                  farms={farms}
                  data={selectedField}
                  satellitesImages={selectedImages}
                  selectedData={dataDisplayed}
                  drawn={polygonDisplayed}
                  draw={mapConfig.draw}
                  edit={mapConfig.edit}
                  zoom={coords.zoom}
                  center={coords.center}
                  fromAction={coords.fromAction}
                  mapRef={mapRef} // Passer la référence de la carte au composant SatteliteMap
                />
              </Col>
            </Row>
          </Col>

          <Col lg='4' md="12" sm="12" className='my-2'>
            <Card className="mt-0" style={{ height: "100%" }}>
              {fields.length === 0
                ? <LinearProgress />  // Affiche une barre de progression si les champs ne sont pas chargés
                : <>
                  <CardHeader className="border-bottom">
                    <FormSelect value={selectedField} onChange={getSelectedField}>
                      <option value="">{t('choisir_champ')}</option>
                      {fields.map(field => {
                        return (
                          <option key={field.Id} value={field.Id}>{field.title}</option>
                        );
                      })}
                    </FormSelect>
                  </CardHeader>
                  <CardBody className="p-1">
                    {selectedField.length > 0 && selectedField.map(field => {
                      return (
                        <>
                          <Row>
                            <Col lg='12' md="12" sm="12">
                              <h5 style={{ fontSize: 14, textAlign: "center" }}>{t('lat')} <span style={{ fontWeight: 'bold', textAlign: "center" }}>{field.Latitude}</span></h5>
                            </Col>
                            <Col lg='12' md="12" sm="12">
                              <h5 style={{ fontSize: 14, textAlign: "center" }}>{t('lon')} <span style={{ fontWeight: 'bold', textAlign: "center" }}>{field.Longitude}</span></h5>
                            </Col>
                          </Row>
                          <Row className="border-bottom">
                            <Col lg='12' md="12" sm="12">
                              <h6 style={{ fontSize: 14, textAlign: "center" }}>{t('nom_champ')} <br /> <span style={{ fontWeight: 'bold', textAlign: "center" }}>{field.title}</span></h6>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    {selectedField.length === 0 && (
                      <div style={{ color: '#bebebe', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '10px' }}>
                        <p style={{ color: '#bebebe', textAlign: "center" }}>{t('message_image')}</p>
                        <i className="fas fa-satellite" style={{ fontSize: "40px" }}></i>
                      </div>
                    )}
                    <Row className="my-2">
                      <Col lg='12' md="12" sm="12">
                        {selectedField.length > 0 && (
                          <>
                            <h5 style={{ fontSize: 14, textAlign: "center" }}> Liste des images satellite</h5>
                            <Calendar
                              onChange={(date) => handleDateClick(moment(date).format('D MMM YYYY'))}
                              value={moment(selectedDate, 'D MMM YYYY').toDate()}
                            />
                            {selectedImages.length > 0 && selectedImages.map((image, index) => (
                              <div
                                key={index}
                                onClick={() => handleClick(image)}
                                style={{
                                  border: '1px solid #ebebeb',
                                  fontSize: 12,
                                  textAlign: 'center',
                                  borderRadius: 6,
                                  padding: 3,
                                  background: dataDisplayed === image ? '#8ad3dd' : 'transparent',
                                  cursor: 'pointer',
                                }}
                              >
                                {image.data_name}
                              </div>
                            ))}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SatelliteImages;
