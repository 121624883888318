import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Polygon, Marker, Popup, FeatureGroup, ScaleControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { EditControl } from "react-leaflet-draw";

const SatteliteMap = ({ data, draw, edit, _onEdited, satellitesImages }) => {
  const [overlayBounds, setOverlayBounds] = useState(null);
  const [drawnPolygon, setDrawnPolygon] = useState([]);
  const [selectedDesignationUrl, setSelectedDesignationUrl] = useState(null);
  const [min, setMin] = useState('-');
  const [max, setMax] = useState('-');
  const [designationData, setDesignationData] = useState([]);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    // Update markers when selected field data changes
    if (data && data.length > 0) {
      const { Latitude, Longitude } = data[0];
      setMarkers([Latitude, Longitude]);
    }
  }, [data]);

  useEffect(() => {
    // Update drawn polygon with selected field coordinates
    if (data.length > 0) {
      let coordinates = [];
      data.forEach(field => {
        let coord = JSON.parse(field.coordinates);
        if (coord) {
          coord.forEach(co => {
            coordinates.push(Object.values(co));
          });
        }
      });
      setDrawnPolygon(coordinates);
    }
  }, [data]);

  useEffect(() => {
    // Update designation data with available satellite images
    if (satellitesImages.length > 0) {
      setDesignationData(satellitesImages[0].data);
    }
  }, [satellitesImages]);

  const calculateBounds = (coordinates) => {
    // Calculate bounds for coordinates
    const latitudes = coordinates.map(coord => coord[1]);
    const longitudes = coordinates.map(coord => coord[0]);
    const bounds = [
      [Math.min(...latitudes), Math.min(...longitudes)],
      [Math.max(...latitudes), Math.max(...longitudes)]
    ];
    return bounds;
  };

  const handleDesignationChange = (event) => {
    // Update selected image URL and adjust overlay bounds
    const selected = event.target.value;
    const { url, min, max } = designationData[selected];
    const bounds = calculateBounds(drawnPolygon);
    setSelectedDesignationUrl(url);
    setOverlayBounds(bounds);
    setMin(parseFloat(min).toFixed(2));
    setMax(parseFloat(max).toFixed(2));
  };

  const mapRef = useRef(null);

  const getCenterFromField = () => {
    // Get field center for map centering
    if (data && data.length > 0) {
      const firstField = data[0];
      return [Number(firstField.Latitude), Number(firstField.Longitude)];
    }
    return null;
  };

  useEffect(() => {
    const updateMapCenter = () => {
      const centerFromFields = getCenterFromField();
      if (centerFromFields && mapRef.current) {
        mapRef.current.setView(centerFromFields, 17);
      }
    };
    updateMapCenter();
  }, [data]);

  return (
    <div>
      <MapContainer
        style={{ borderRadius: 20, boxShadow: '1px 1px 10px #bbb', height: 300 }}
        zoom={17}
        center={getCenterFromField()}
        whenCreated={(map) => (mapRef.current = map)}
      >
        <FeatureGroup>
          <EditControl draw={draw} edit={edit} position="topright" onEdited={_onEdited} />
          <ScaleControl position="bottomleft" />
        </FeatureGroup>
        <TileLayer
          maxNativeZoom={18}
          maxZoom={20}
          url={selectedDesignationUrl || 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {markers.length > 0 && (
          <Marker position={markers}>
            <Popup>{data[0].title}</Popup>
          </Marker>
        )}
        {drawnPolygon.length > 0 && (
          <Polygon
            pathOptions={{ color: '#26A6B7', opacity: 0.5 }}
            positions={drawnPolygon}
          />
        )}
        {selectedDesignationUrl && overlayBounds && (
          <TileLayer
            maxNativeZoom={18}
            maxZoom={20}
            key={selectedDesignationUrl}
            url={selectedDesignationUrl}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default SatteliteMap;
