import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import api from '../../api/api'
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import { Col, Row, Container, Card } from "shards-react";
import Background from '../../images/background-signup.png';

export const Register = ({ setToken }) => {
  const history = useHistory();
  const [hasAccepted, setAccepted] = useState(false);
  const [name, setName] = useState("");
  const [num, setNum] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState(""); 
  const [sendEmail, setSendEmail] = useState(false); 
  const [local, setLocal] = useState(localStorage.getItem("local") || "en");
  const [nameError, setNameErr] = useState("");
  const [emailError, setEmailErr] = useState("");
  const [mdpError, setPassErr] = useState("");
  const [confMdpErr, setconfMdpErr] = useState("");
  const [offerType, setOfferType] = useState("");
  const [role, setRole] = useState("ROLE_USER");
  const { t, i18n } = useTranslation();

  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    password: false,
    confirmPass: false,
    num: false,
  });

  const handleBlur = (field) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
  };

  const validate = () => {
    let valid = true;
    if (!name) {
      setNameErr('Username cannot be blank!');
      valid = false;
    } else {
      setNameErr('');
    }
    
    if (!email) {
      setEmailErr('Email cannot be blank!');
      valid = false;
    } else {
      var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!mailformat.test(email)) {
        setEmailErr('Incorrect email format!');
        valid = false;
      } else {
        setEmailErr('');
      }
    }

    if (password.length < 6) {
      setPassErr('Password cannot be less than 6 characters!');
      valid = false;
    } else {
      setPassErr('');
    }

    if (password !== confirmPass) {
      setconfMdpErr('Passwords do not match!');
      valid = false;
    } else {
      setconfMdpErr('');
    }

    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      handleRegister();
    } else {
      return false;
    }
  };

  const handleRegister = async () => {
    let data = {
      name: name,
      email: email,
      phone_number: num, 
      password: password,
      confirmPassword: confirmPass,
      offer_type: offerType,
      role: role
    };
    if (role === "ROLE_SUPPLIER") {
      data.offer_type = '2';
    }
    await api.post('/signup', data)
      .then(res => {
        if (res.data.type && res.data.type === "danger") {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: res.data.message
          });
          return false;
        }
        if (res.data.type && res.data.type === "warning") {
          swal({
            icon: 'warning',
            title: 'Oops...',
            text: res.data.message
          });
          setSendEmail(true, resetForm());
        }
        if (res.data.type && res.data.type === "success") {
          swal({
            icon: 'success',
            title: 'OK',
            text: 'Email sent, check your inbox to confirm'
          });
          setSendEmail(true, resetForm());
          history.push('/login'); // Navigate to login page
        }
      }).catch(() => {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to add user'
        });
        return false;
      });
  };

  const resetForm = () => {
    setNameErr('');
    setEmailErr('');
    setPassErr('');
    setconfMdpErr('');
    setName("");
    setEmail("");
    setNum('');
    setPassword("");
    setConfirmPass("");
    setOfferType("");
    setTimeout(() => {
      setSendEmail(false);
    }, 3000);
  };

  const changeLanguageHandler = async (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
    localStorage.setItem("local", languageValue);
    setLocal(languageValue);
  };

  return (
    <Container fluid className="main-content-container p-4">
      <Row className="d-flex justify-content-center align-items-center">
        <Col lg="8" md="12" sm="12">
          <Card className="p-4">
            <Row>
              <Col lg="5" md="12" sm="12" className="border-right" style={{ backgroundImage: `url(${Background})` }}>
                <div className="pt-5">
                  <div>
                    <img
                      style={{ width: "100%" }}
                      src={require("../../images/Logo smart farm1.jpg")}
                      alt="Smart Farm"
                    />
                  </div>
                  <h4 style={{ fontFamily: "'Satisfy', cursive", color: "#25A6B8", textAlign: "center" }}>{t('agriculture_intelligente')}</h4>
                </div>
              </Col>
              <Col lg="7" md="12" sm="12">
                <div className="col-lg-12 text-right p-0">
                  <select value={local} className="custom-select" style={{ width: 70 }} onChange={changeLanguageHandler}>
                    <option value="en">En</option>
                    <option value="fr">Fr</option>
                    <option value="it">It</option>
                    <option value="ar">Ar</option>
                  </select>
                </div>
                <h3 style={{ textAlign: "center" }}>{t('Sign up')}</h3>
                <div className="text-center mt-2 font-weight-light">
                  <span>{t('already_account')} </span> 
                  <Link to="/Login" style={{ color: "#0daaa2" }}>{t('sign_in')}</Link>
                </div>
                <form className="pt-3" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6" md="12" sm="12">
                      <div className="form-group">
                        <input
                          value={name}
                          type="text"
                          className={nameError && touchedFields.name ? 'form-control is-invalid' : 'form-control'}
                          placeholder={t('name')}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={() => handleBlur('name')}
                        />
                        {nameError && touchedFields.name && (
                          <div className="text-danger mt-1">
                            {nameError}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          value={email}
                          type="email"
                          className={emailError && touchedFields.email ? 'form-control is-invalid' : 'form-control'}
                          placeholder={t('email')}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={() => handleBlur('email')}
                        />
                        {emailError && touchedFields.email && (
                          <div className="text-danger mt-1">
                            {emailError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg="6" md="12" sm="12">
                      <div className="form-group">
                        <input
                          value={password}
                          type="password"
                          className={mdpError && touchedFields.password ? 'form-control is-invalid' : 'form-control'}
                          placeholder={t('Password')}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={() => handleBlur('password')}
                        />
                        {mdpError && touchedFields.password && (
                          <div className="text-danger mt-1">
                            {mdpError}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          value={confirmPass}
                          type="password"
                          className={confMdpErr && touchedFields.confirmPass ? 'form-control is-invalid' : 'form-control'}
                          placeholder={t('ConfirmPassword')}
                          onChange={(e) => setConfirmPass(e.target.value)}
                          onBlur={() => handleBlur('confirmPass')}
                        />
                        {confMdpErr && touchedFields.confirmPass && (
                          <div className="text-danger mt-1">
                            {confMdpErr}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="12" sm="12">
                      <div className="form-group">
                        <input
                          value={num}
                          type="tel"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="form-control"
                          placeholder={t('num')}
                          onChange={(e) => { setNum(e.target.value); }}
                          onBlur={() => handleBlur('num')}
                        />
                      </div>
                    </Col> 
                    <Col lg="6" md="12" sm="12">
                      {role === "ROLE_USER" &&
                        <div className="form-group">
                          <select  
                            className="form-control"
                            value={offerType} 
                            onChange={(e) => setOfferType(e.target.value)}>
                            <option value="" disabled>{t('select_offer')}</option>
                            <option value="1">{t('gratuit')}</option>
                            <option value="2">{t('payante')}</option>
                          </select>
                        </div>
                      }
                    </Col>
                  </Row>          
                  <div className="mb-4">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input
                          onClick={() => setAccepted(!hasAccepted)}
                          type="checkbox"
                          className="form-check-input"
                          required
                        />
                        <i className="input-helper"></i>{t('i_agree')}
                      </label>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn btn-block btn-lg font-weight-medium auth-form-btn"
                      style={{ color: 'white', backgroundColor: '#27A6B7' }}
                      type="submit"
                    >
                      {t('Sign up')}
                    </button>
                  </div>
                  <div className="text-center d-flex justify-content-between align-items-center mt-4 font-weight-light">
                    <Link to="/Terms" style={{ color: "#0daaa2" }}>{t('Terms & Conditions')}</Link>
                    <Link to="/Privacy" style={{ color: "#0daaa2" }}>{t('Privacy Policy')}</Link>
                  </div>
                </form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Register.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Register;
