import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import LeafletMap from "./leafletMap";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { withTranslation } from "react-i18next";
import api from '../api/api';
import swal from "sweetalert";
import FarmList from "./FarmList";
class AddFarm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      cities: [],
      allCities: [],
      countries: [],
      country: "",
      city: '',
      cityName: "",
      name_group: null,
      zoom: "",
      center: [],
      fromAction: false,
      name: null,
      description: null,
      added: false,
      nameError: "",
      classInputError: "",
      msgServer: "",
      classMsg: "",
      displayMsg: "hide",
      iconMsg: "info",
      farmList: [],
      Latitude: "",
      Longitude: "",
      positionError: "",
      layer: null,
      draw: {
        polygon: true,
        circle: false,
        rectangle: false,
        polyline: false,
        marker: true,
        circlemarker: false,
      },
      edit: {
        delete: false,
        edit: false
      },
      layerType: "",
      checked: false,
      toggle: false
    };
  }

  componentDidMount = async () => {
    this.getFarms();
    this.getCities();
    this.getCountries();
  }

  getCities = async () => {
    await api.get('/cities/get-cities').then(res => {
      const cities = res.data.Cities;
      this.setState({ allCities: cities });
    });
  }

  getCountries = async () => {
    await api.get('/countries/get-countries').then(res => {
      const countries = res.data.Countries;
      this.setState({ countries: countries });
    });
  }

  getFarms = async () => {
    await api.get('/farm/farms').then(res => {
      const DataFarm = res.data;
      this.setState({ farmList: DataFarm.farms });
    }).catch(err => {
      console.log(err);
    }).finally(() => this.setState({ isLoading: false }));
  }

  _onCreated = e => {
    let type = e.layerType;
    this.setState({ layerType: type })
    let layer = e.layer;
    if (type === "polygon") {
      let coords = layer._latlngs[0];
      const Coordinates = coords.map((coord) => ({
        Lat: coord.lat,
        Long: coord.lng,
      }));
      if (Coordinates)
        this.setState({ layer: JSON.stringify(Coordinates) })
    } else {
      this.setState({ Latitude: layer.getLatLng().lat });
      this.setState({ Longitude: layer.getLatLng().lng });
    }
  };

  successCallback = (position) => {
    this.setState({ Latitude: position.coords.latitude });
    this.setState({ Longitude: position.coords.longitude });
    this.setState({
      zoom: 10,
      center: [position.coords.latitude, position.coords.longitude],
      fromAction: true
    });
  }

  errorCallback = (error) => {
    this.setState({
      checked: false
    });
    this.setState({ Latitude: "" });
    this.setState({ Longitude: "" });

    if (error.code == 1) {
      alert("Vous avez décidé de ne pas partager votre position, mais c'est OK. Nous ne vous le demanderons plus.");
    } else if (error.code == 2) {
      alert("Le réseau est hors service ou le service de localisation est inaccessible.");
    } else if (error.code == 3) {
      alert("La tentative a expiré avant de pouvoir obtenir les données de localisation.");
    } else {
      alert("La géolocalisation a échoué en raison d'une erreur inconnue.");
    }
  }

  checkPosition = () => {
    if (this.state.checked === true) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
      } else {
        this.setState({ Latitude: "" });
        this.setState({ Longitude: "" });
        this.setState({
          checked: false
        });
        alert("Désolé, votre navigateur ne prend pas en charge la géolocalisation HTML5.");
      }
    }
    if (this.state.checked === false) {
      this.setState({ Latitude: "" });
      this.setState({ Longitude: "" });
    }
  }

  handleChange = () => {
    this.setState({
      checked: !this.state.checked
    }, () => this.checkPosition());
  }

  handleChangeZoom = (e) => {
    const target = e.target.value;
    let selected = e.target.selectedOptions[0].text
    const newCoords = target.split("_");
    const lat = parseFloat(newCoords[0]);
    const lon = parseFloat(newCoords[1]);
    const cityId = parseFloat(newCoords[2]);
    if (lat && lon && cityId) {
      this.setState({
        center: [lat, lon],
        city: cityId,
        zoom: 10,
        fromAction: true
      });
    }
  }

  handleCountryPick = (e) => {
    e.preventDefault();
    const country = this.state.countries.find(
      (country) => country.iso === e.target.value
    );
    let allCities = []
    if (country) {
      const city = this.state.allCities.map((city) => {
        if (city.iso === country.iso) {
          allCities.push({
            city: city.city,
            id: city.id,
            lat: city.lat,
            lon: city.lon
          })
        }
      });
      this.setState({ country: country.iso });
      this.setState({ cities: allCities })
    }
  };

  validate = () => {
    let nameError = '';
    let classInputError = '';
    let positionError = ""
    if (!this.state.name || this.state.name == "") {
      nameError = 'not_empty';
      classInputError = 'is-invalid'
      this.setState({ nameError });
      this.setState({ classInputError });
      return false;
    } else {
      this.setState({ nameError: '' });
      this.setState({ classInputError: '' });
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault()
    const isValid = this.validate();
    if (isValid) {
      this.addFarm()
    } else {
      return false;
    }
  }

  addFarm = async () => {
    const name = this.state.name
    const name_group = this.state.name_group
    const description = this.state.description
    const city_id = this.state.city
    let Coordinates = this.state.layer
    let Latitude = this.state.Latitude
    let Longitude = this.state.Longitude
    let user = JSON.parse(localStorage.getItem('user'));
    let user_uid = user.id

    await api.post('/farm/add-farm', { name, name_group, description, city_id, user_uid, Coordinates, Latitude, Longitude })
      .then(res => {
        if (res.data.errors) {
          let errorsData = res.data.errors
          errorsData.map((item, indx) => {
            this.setState({ msgServer: item.msg })
          })
          this.setState({ classMsg: "danger" })
          this.setState({ displayMsg: "show" })
        }
        if (res.data.type && res.data.type === "danger") {
          swal({
            title: `Impossible d'ajouter l'exploitation`,
            icon: "error",
            text: 'Erreur'

          });
        }
        if (res.data.type && res.data.type === "success") {
          swal({
            title: 'Exploitation ajoutée',
            icon: "success",
            text: 'Exploitation ajoutée avec succès '

          });
          this.setState({ added: true }, this.resetForm())
          this.getFarms()
        }
      }).catch((error) => {
        this.setState({ msgServer: "fail_add_farm" })
        this.setState({ classMsg: "danger" })
        this.setState({ displayMsg: "show" })
      })
  }

  resetForm = () => {
    this.setState({
      name: "",
      name_group: "",
      description: "",
    });

    setTimeout(() => {
      this.setState({ displayMsg: "hide" })
      this.setState({ nameError: '' });
      this.setState({ classInputError: '' });
    }, 5000);
  };

  render() {
    const { t } = this.props;

    return (
      <Container fluid className="main-content-container px-4">
        {/* En-tête de la page */}
        <Row noGutters className="page-header py-2 d-flex justify-content-between align-items-center">
          <PageTitle sm="4" title={t('my_farms')} className="text-sm-left" />
          <div className="m-1">
            <Button onClick={() => this.setState({ toggle: true })} theme="info" style={{ fontSize: 16, color: "#fff" }}>
              {t('add_farm')}
            </Button>
          </div>
        </Row>

        {/* Liste des exploitations agricoles */}
        {this.state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Row className="px-2">
            <FarmList farmList={this.state.farmList} Farms={this.getFarms} />
          </Row>
        )}

        {/* Formulaire d'ajout d'une exploitation (affiché lors du toggle) */}
        {this.state.toggle && (
          <>
            <Row noGutters className="page-header py-2">
              <PageTitle sm="4" title={t('add_farm')} className="text-sm-left" />
            </Row>
            <Row>
              <Col lg="12" sm="12" md="12">
                <Card className="pt-0 mb-4">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">{t('farm_details')}</h6>
                  </CardHeader>
                  <CardBody className="p-0 pb-3">
                    <Form onSubmit={this.handleSubmit}>
                      {/* Nom de l'exploitation */}
                      <FormGroup >
                        <label  htmlFor="name">{t('farm_name')}</label>
                        <FormInput 
                        type="name"
                          id="name"
                          placeholder={t('farm_name')}
                          value={this.state.name || ""}
                          onChange={(e) => this.setState({ name: e.target.value })}
                          className={this.state.classInputError}
                        />
                        <div  className="invalid-feedback">{t(this.state.nameError)}</div>
                        {/* Info-bulle pour le nom de l'exploitation */}
                        <small className="form-text text-muted">
                          {t('farm_name_tip')}
                        </small>
                      </FormGroup>

                      {/* Nom du groupe */}
                      <FormGroup>
                        <label htmlFor="name_group">{t('group_name')}</label>
                        <FormInput
                          id="name_group"
                          placeholder={t('group_name')}
                          value={this.state.name_group || ""}
                          onChange={(e) => this.setState({ name_group: e.target.value })}
                        />
                        {/* Info-bulle pour le nom du groupe */}
                        <small className="form-text text-muted">
                          {t('group_name_tip')}
                        </small>
                      </FormGroup>

                      {/* Description */}
                      <FormGroup>
                        <label htmlFor="description">{t('description')}</label>
                        <FormInput
                          type="textarea"
                          id="description"
                          placeholder={t('description')}
                          value={this.state.description || ""}
                          onChange={(e) => this.setState({ description: e.target.value })}
                        />
                        {/* Info-bulle pour la description */}
                        <small className="form-text text-muted">
                          {t('description_tip')}
                        </small>
                      </FormGroup>

                      {/* Pays et ville */}
                      <FormGroup>
                        <label htmlFor="country">{t('country')}</label>
                        <FormSelect
                          id="country"
                          onChange={(e) => this.handleCountryPick(e)}
                          value={this.state.country || ""}
                        >
                          <option value="">{t('select_country')}</option>
                          {this.state.countries.map((country, index) => (
                            <option key={index} value={country.iso}>
                              {country.name}
                            </option>
                          ))}
                        </FormSelect>
                        {/* Info-bulle pour le pays */}
                        <small className="form-text text-muted">
                          {t('country_tip')}
                        </small>
                      </FormGroup>

                      <FormGroup>
                        <label htmlFor="city">{t('city')}</label>
                        <FormSelect
                          id="city"
                          onChange={(e) => this.handleChangeZoom(e)}
                          value={this.state.city || ""}
                        >
                          <option value="">{t('select_city')}</option>
                          {this.state.cities.map((city, index) => (
                            <option
                              key={index}
                              value={`${city.lat}_${city.lon}_${city.id}`}
                            >
                              {city.city}
                            </option>
                          ))}
                        </FormSelect>
                        {/* Info-bulle pour la ville */}
                        <small className="form-text text-muted">
                          {t('city_tip')}
                        </small>
                      </FormGroup>

                      {/* Carte et localisation */}
                      <FormGroup>
                        <label>{t('map_and_location')}</label>
                        <LeafletMap
                          height="300px"
                          center={this.state.center}
                          zoom={this.state.zoom}
                          onCreated={this._onCreated}
                          draw={this.state.draw}
                          edit={this.state.edit}
                        />
                        <FormCheckbox
                          toggle
                          checked={this.state.checked}
                          onChange={() => this.handleChange()}
                        >
                          {t('use_geolocation')}
                        </FormCheckbox>
                        {/* Info-bulle pour la carte et la localisation */}
                        <small className="form-text text-muted">
                          {t('map_and_location_tip')}
                        </small>
                      </FormGroup>

                      {/* Bouton de soumission */}
                      <Button type="submit" theme="accent">
                        {t('add_farm')}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}

export default withTranslation()(AddFarm);
