import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';





// Render the application with GoogleOAuthProvider
ReactDOM.render(
  <GoogleOAuthProvider clientId="106695830492-kro32huj5ogea1oma6kjmf7dn8pjjclu.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
