import { useState } from "react";

export default function useToken() {
  var param = true;
  
  const getToken = () => {
    var tokenString = "";
    if (param == true) {
      tokenString = localStorage.getItem("token");
    } else {
      tokenString = sessionStorage.getItem("token");
    }

    // Retourner le token directement, pas besoin de JSON.parse
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (param == true) {
      localStorage.setItem("token", userToken);
    } else {
      sessionStorage.setItem("token", userToken);
    }

    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  };
}
