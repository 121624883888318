// src/components/Navbar/NavbarToggle.js
import React from 'react';
import PropTypes from 'prop-types';

const NavbarToggle = ({ toggleSidebar, isSidebarOpen }) => {
  return (
    <nav className="nav">
      <button
        onClick={toggleSidebar}
        className="btn btn-primary d-none d-lg-inline toggle-button"
        style={{ left: isSidebarOpen ? '100px' : '-90px' }}
      >
        ☰
      </button>
    </nav>
  );
};

NavbarToggle.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
};

export default NavbarToggle;
