import React, { useState, useEffect } from "react";
import api from '../../api/api';
import countryState from '../../data/gistfile.json';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import { useTranslation } from "react-i18next";

export default function UserAccountDetails() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zip_code, setZipCode] = useState("");
  const [description, setDescription] = useState("");
  const [other, setOther] = useState("");
  const [allCountry, setAllCountry] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [currentMdpError, setCurrentPassErr] = useState("");
  const [newMdpError, setNewPassErr] = useState("");
  const [confMdpErr, setConfMdpErr] = useState("");
  const [msgServer, setMsgServer] = useState("");
  const [classMsg, setClassMsg] = useState("");
  const [displayMsg, setDisplayMsg] = useState("hide");
  const [iconMsg, setIconMsg] = useState("info");
  const [touchedFields, setTouchedFields] = useState({});
  const [errorMsg, setErrorMsg] = useState({ nameError: "", emailError: "" });
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Déclaration des variables pour stocker les pays et les états sélectionnés
  let listcountry = [];
  let liststateSelectedCountry = [];

  useEffect(() => {
    const fetchData = async () => {
      countryState.countries.forEach((item) => {
        listcountry.push(item.country);
      });
      setAllCountry(listcountry);

      await api.get('/profil').then(res => {
        const newData = res.data;
        setName(newData.result.name);
        setEmail(newData.result.email);
        setAddress(newData.result.address);
        setCity(newData.result.city);
        setOther(newData.result.city);
        setCountry(newData.result.country);
        setZipCode(newData.result.zip_code);
        setDescription(newData.result.description);
        countryState.countries.forEach((item) => {
          if (item.country === newData.result.country) {
            item.states.forEach((state) => {
              liststateSelectedCountry.push(state);
            });
          }
        });
        setAllStates(liststateSelectedCountry);
      });
    };
    fetchData();
  }, []);

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const validate = () => {
    let nameError = '';
    let emailError = '';
    if (!name) {
      nameError = 'not_empty';
    }
    if (!email) {
      emailError = 'not_empty_mail';
    }
    setErrorMsg({ nameError, emailError });
    return !(nameError || emailError);
  };

  const handleCountry = (country) => {
    setCountry(country);
    liststateSelectedCountry = [];
    allCountry.forEach((item, indx) => {
      if (item === country) {
        countryState.countries[indx].states.forEach((state) => {
          liststateSelectedCountry.push(state);
        });
      }
    });
    setAllStates(liststateSelectedCountry);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      handlSaveProfil();
    }
  };

  const handlSaveProfil = () => {
    const dataPost = {
      name,
      email,
      address,
      city,
      country,
      zip_code,
      description,
    };
    api.post('/edit-profil', dataPost)
      .then(res => {
        const type = res.data.type;
        const message = res.data.message;
        if (type === "danger") {
          setMsgServer(message);
          setClassMsg("danger");
          setDisplayMsg("show");
          setIconMsg("info");
        } else if (type === "success") {
          const user = JSON.parse(localStorage.getItem("user"));
          user.name = name;
          user.email = email;
          localStorage.setItem("user", JSON.stringify(user));
          setMsgServer(message);
          setClassMsg("success");
          setDisplayMsg("show");
          setIconMsg("check");
          setModalMessage("Profile updated successfully!");
          setIsModalOpen(true);
        }
      }).catch(() => {
        setMsgServer("error_save_change");
        setClassMsg("danger");
        setDisplayMsg("show");
        setIconMsg("info");
      });
  };

  const validatePass = () => {
    let currentMdpError = '';
    let newMdpError = '';
    let confMdpErr = '';
    if (password.length < 6) {
      currentMdpError = 'Current Password Error !';
    }
    if (newPassword.length < 6) {
      newMdpError = 'Password cannot be less than 6 characters!';
    }
    if (newPassword !== confirmPass) {
      confMdpErr = `Passwords don't match`;
    }
    setCurrentPassErr(currentMdpError);
    setNewPassErr(newMdpError);
    setConfMdpErr(confMdpErr);
    return !(currentMdpError || newMdpError || confMdpErr);
  };

  const handleSubmitPass = (e) => {
    e.preventDefault();
    if (validatePass()) {
      handleChangePass();
    }
  };

  const handleChangePass = () => {
    const data = {
      password,
      newPassword,
      confirmNewPassword: confirmPass,
    };
    api.post('/profil/changepassword', data)
      .then(res => {
        const type = res.data.type;
        const message = res.data.message;
        if (type === "danger") {
          setMsgServer(message);
          setClassMsg("danger");
          setDisplayMsg("show");
          setIconMsg("info");
        } else if (type === "success") {
          setMsgServer(message);
          resetForm();
          setClassMsg("success");
          setDisplayMsg("show");
          setIconMsg("check");
          setModalMessage("Password changed successfully!");
          setIsModalOpen(true);
        }
      }).catch(() => {
        setMsgServer("error_save_change");
        setClassMsg("danger");
        setDisplayMsg("show");
        setIconMsg("info");
      });
  };

  const resetForm = () => {
    setPassword("");
    setNewPassword("");
    setConfirmPass("");
    setTimeout(() => {
      setDisplayMsg('hide');
    }, 3000);
  };

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0"></h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <div className={`mb-0 alert alert-${classMsg} fade ${displayMsg}`}>
            <i className={`fa fa-${iconMsg} mx-2`}></i> {t(msgServer)}
          </div>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">{t('name')}</label>
                    <FormInput
                      id="feFirstName"
                      placeholder={t('name')}
                      className={touchedFields.name && errorMsg.nameError ? 'is-invalid' : ''}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onBlur={() => handleBlur('name')}
                    />
                    <div className="invalid-feedback">{touchedFields.name && errorMsg.nameError && t(errorMsg.nameError)}</div>
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">{t('email')}</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder={t('email')}
                      className={touchedFields.email && errorMsg.emailError ? 'is-invalid' : ''}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => handleBlur('email')}
                    />
                    <div className="invalid-feedback">{touchedFields.email && errorMsg.emailError && t(errorMsg.emailError)}</div>
                  </Col>
                </Row>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feAddress">{t('address')}</label>
                    <FormInput
                      id="feAddress"
                      placeholder={t('address')}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Col>
                  <Col md="4" className="form-group">
                    <label htmlFor="feCountry">{t('country')}</label>
                    <FormSelect
                      id="feCountry"
                      placeholder={t('country')}
                      value={country}
                      onChange={(e) => handleCountry(e.target.value)}
                    >
                      <option>{other}</option>
                      {allCountry.map((country, i) => (
                        <option key={i}>{country}</option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="feCity">{t('city')}</label>
                    <FormSelect
                      id="feCity"
                      placeholder={t('city')}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option>{other}</option>
                      {allStates.map((state, i) => (
                        <option key={i}>{state}</option>
                      ))}
                    </FormSelect>
                    <input type="checkbox" name="Autre" id="check" onClick={() => setToggle(!toggle)} /> {t('other')}
                    {toggle && (
                      <FormInput
                        placeholder={t('city')}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    )}
                  </Col>
                  <Col md="2" className="form-group">
                    <label htmlFor="feZipCode">{t('zip')}</label>
                    <FormInput
                      id="feZipCode"
                      placeholder={t('zip')}
                      value={zip_code}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feDescription">{t('desc')}</label>
                    <FormTextarea
                      id="feDescription"
                      rows="5"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Col>
                </Row>
                <Button theme="accent" type="submit">{t('update_btn')}</Button>
              </Form>
            </Col>
          </Row>
          <hr />
          <Row form className="p-1">
            <Form onSubmit={handleSubmitPass}>
              <h5 className="pb-3 pt-3">{t('pass_change')}</h5>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="feCurrentPassword">{t('current_pass')}</label>
                  <FormInput
                    type="password"
                    id="feCurrentPassword"
                    placeholder={t('current_pass')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`form-control form-control-md ${currentMdpError ? "is-invalid" : ""}`}
                  />
                  <div className="invalid-feedback">{currentMdpError}</div>
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feNewPassword">{t('new_pass')}</label>
                  <FormInput
                    type="password"
                    id="feNewPassword"
                    placeholder={t('new_pass')}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={`form-control form-control-md ${newMdpError ? "is-invalid" : ""}`}
                  />
                  <div className="invalid-feedback">{newMdpError}</div>
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feConfNewPass">{t('conf_pass')}</label>
                  <FormInput
                    type="password"
                    id="feConfNewPass"
                    placeholder={t('conf_pass')}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    className={`form-control form-control-md ${confMdpErr ? "is-invalid" : ""}`}
                  />
                  <div className="invalid-feedback">{confMdpErr}</div>
                </Col>
              </Row>
              <Button theme="accent" type="submit">{t('change_btn')}</Button>
            </Form>
          </Row>
        </ListGroupItem>
      </ListGroup>
      <Modal open={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader>{t('success')}</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <Button theme="accent" onClick={() => setIsModalOpen(false)}>{t('close')}</Button>
      </Modal>
    </Card>
  );
}
