import React, { useRef, useState, useEffect } from "react";
import { Card, CardHeader, Button, ListGroup, ListGroupItem, Progress } from "shards-react";
import api from '../../api/api';
import { useTranslation } from "react-i18next";

const img = require("./../../images/avatars/default-avatar.png");

const getLastLoginFromLocalStorage = () => {
  const lastLogin = localStorage.getItem("lastLogin");
  return lastLogin ? new Date(lastLogin).toLocaleString() : "Non disponible";
};

const UserDetails = () => {
  const [data, setData] = useState({ name: "", email: "", address: "", upload_file_name: "", lastLogin: getLastLoginFromLocalStorage() });
  const [selectedFile, setSelectedFile] = useState(null);
  const [msgServer, setMsgServer] = useState("");
  const [classMsg, setClassMsg] = useState("");
  const [displayMsg, setDisplayMsg] = useState("hide");
  const [iconMsg, setIconMsg] = useState("info");
  const { t } = useTranslation();
  const fileRef = useRef();

  const handleChange = async (e) => {
    const [file] = e.target.files;
    setSelectedFile(file);
  };

  const handleSubmitFile = async (e) => {
    const formData = new FormData();
    formData.append("userPhoto", selectedFile, selectedFile.name);

    if (selectedFile.size > 1 * 1024 * 1024) {
      setMsgServer("ERROR - Max size 1MB");
      setClassMsg("danger");
      setDisplayMsg("show");
      setIconMsg("info");
      return false;
    }

    if (["image/png", "image/jpg", "image/jpeg"].includes(selectedFile.type)) {
      try {
        const response = await api.post('/upload-avatar', formData);
        const user = JSON.parse(localStorage.getItem("user"));
        user.avatar = `userPhoto-${user.id}.jpeg`;
        localStorage.setItem("user", JSON.stringify(user));
        setMsgServer(response.data.message);
        setClassMsg("success");
        setDisplayMsg("show");
        setIconMsg("check");
        window.location.reload();
      } catch {
        setMsgServer("ERROR - Upload file");
        setClassMsg("danger");
        setDisplayMsg("show");
        setIconMsg("info");
      }
    } else {
      setMsgServer("ERROR - Only .png, .jpg and .jpeg format allowed!");
      return false;
    }
  };

  return (
    <Card small className="mb-4 pt-3">
      <div className={`mb-0 alert alert-${classMsg} fade ${displayMsg}`}>
        <i className={`fa fa-${iconMsg} mx-2`}></i> {t(msgServer)}
      </div>
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto" style={{ height: "110px" }}>
          <img
            className="rounded-circle h-100"
            src={data.upload_file_name === null ? img : `${process.env.REACT_APP_BASE_URL}/static/${data.upload_file_name}`}
            alt={data.name}
            width="110"
          />
        </div>
        <h4 className="mb-0">{data.name}</h4>
        <Button pill outline size="sm" className="mb-2" onClick={() => fileRef.current.click()}>
          {t('avatar')}
        </Button>
        {selectedFile && <Button pill outline size="sm" className="mb-2 success" onClick={handleSubmitFile}>{t('upload')}</Button>}
        <input ref={fileRef} onChange={handleChange} type="file" hidden />

        <p>{t('last_login')}: {data.lastLogin}</p>

      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">{t('workload')}</strong>
            <Progress className="progress-sm" value="100">
              <span className="progress-value">100%</span>
            </Progress>
          </div>
        </ListGroupItem>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">{t('description')}</strong>
          <span>{data.description}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default UserDetails;
