import React, { useEffect, useState } from 'react';
import { Container, Row, Col, FormGroup, FormInput, Button, ButtonGroup, Card, Nav, NavItem, NavLink } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import api from '../api/api';
import swal from 'sweetalert';
import Pagination from '../views/Pagination';
import { useHistory, Link } from 'react-router-dom';

const UsersList = () => {
    const [usersPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);

    const { t } = useTranslation();
    const [msgServer, setMsg] = useState("");
    const [classMsg, setCmsg] = useState("");
    const [displayMsg, setDispMsg] = useState("hide");
    const [iconMsg, setIconMsg] = useState("info");
    const [toggle, setToggle] = useState(false);
    const [singleUser, setSingleUser] = useState([]);
    const [filteredResult, setFilteredResult] = useState([]);
    const [SearchEmail, setSearchEmail] = useState('');
    const [SearchName, setSearchName] = useState('');
    const [SearchRole, setSearchRole] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [existSuppliers, setExistSuppliers] = useState([]);
    const [users, setUsers] = useState([]);

    let role = JSON.parse(localStorage.getItem('user')).role;

    useEffect(() => {
        const getExistSuppliers = async () => {
            await api.get('/admin/exist-suppliers')
                .then(response => {
                    var Data = response.data.suppliers;
                    setExistSuppliers(Data);
                }).catch(err => {
                    console.log(err);
                });
        };
        getExistSuppliers();
        getUsersList();
    }, []);

    useEffect(() => {
        setUsers(filteredResult);
    }, [filteredResult]);

    const getUsersList = async () => {
        let data = await api.get("/admin/users")
            .then(response => {
                let usersData = response.data.users;
                setUsers(usersData);
            });
    };

    const handleDelete = async userUid => {
        let data = {
            user_uid: userUid,
        };
        await api.delete('/admin/delete-user', { data: data })
            .then(response => {
                if (response.data.type && response.data.type === "danger") {
                    swal({
                        title: `${t('cannot_delete')}`,
                        icon: "warning",
                    });
                }
                if (response.data.type === "success") {
                    getUsersList();
                    setMsg(`${t('delete_success_user')}`);
                    setCmsg("success");
                    setDispMsg("show");
                    setIconMsg("check");
                    hideMsg();
                }
            }).catch(error => {
                swal({
                    title: `${t('cannot_delete')}`,
                    icon: "error",
                    text: 'Error',
                });
            });
    };

    const hideMsg = () => {
        setTimeout(() => {
            setDispMsg("hide");
        }, 3000);
    };

    const confirmDelete = userUid => {
        swal({
            title: `${t('are_you_sure')}`,
            text: `${t('confirm_delete')}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((Delete) => {
                if (Delete) {
                    handleDelete(userUid);
                    swal(`${t('delete_success_user')}`, {
                        icon: "success",
                    });
                }
            }).catch(error => {
                swal({
                    title: `${t('cannot_delete')}`,
                    icon: "error",
                    text: 'Error',
                });
            });
    };

    const getSingleUser = async (userUid) => {
        let data = {
            user_uid: userUid,
        };

        await api.post('/admin/single-user', data)
            .then(res => {
                let UserData = res.data.user;
                setSingleUser(UserData);
                setName(UserData.name);
                setEmail(UserData.email);
                setCity(UserData.city);
            }).catch(error => {
                console.log(error);
            });
        setToggle(!toggle);
    };

    const validate = () => {
        let nameErr = '';
        if (!name) {
            nameErr = 'Cannot be blank!';
            setNameErr(nameErr);
            return false;
        } else {
            setNameErr("");
            return true;
        }
    };

    const handleEdit = (userUid) => {
        let data = {
            name: name,
            email: email,
            user_uid: userUid,
        };

        let isValid = validate();

        if (isValid) {
            api.post('/admin/edit-user', data)
                .then(response => {
                    if (response.data.type === "success") {
                        swal(" user has been updated", {
                            icon: "success",
                        });
                        getUsersList();
                        setToggle(false);
                    }
                    if (response.data.type && response.data.type === "danger") {
                        swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Error',
                        });
                        return false;
                    }
                }).catch(error => {
                    swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'error_edit_user',
                    });
                });
        }
    };

    const desactivateUser = (userUid) => {
        let data = {
            user_uid: userUid,
        };
        api.post('/admin/desactivate-user', data)
            .then(response => {
                if (response.data.type === "success") {
                    swal("user has been desactivated", {
                        icon: "success",
                    });
                    getUsersList();
                }
                if (response.data.type && response.data.type === "danger") {
                    swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'error_desactivate_user',
                    });
                    return false;
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'error_desactivate_user',
                });
            });
    };

    const activateUser = (userUid) => {
        let data = {
            user_uid: userUid,
        };
        api.post('/admin/activate-user', data)
            .then(response => {
                if (response.data.type === "success") {
                    swal("user has been activated", {
                        icon: "success",
                    });
                    getUsersList();
                }
                if (response.data.type && response.data.type === "danger") {
                    swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'error_activate_user',
                    });
                    return false;
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'error_activate_user',
                });
            });
    };

    const confirmUser = (userUid) => {
        let data = {
            user_uid: userUid,
        };
        api.post('/admin/confirm-user', data)
            .then(response => {
                if (response.data.type === "success") {
                    swal("user has been activated", {
                        icon: "success",
                    });
                    getUsersList();
                }
                if (response.data.type && response.data.type === "danger") {
                    swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'error_activate_user',
                    });
                    return false;
                }
            }).catch(error => {
                swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'error_activate_user',
                });
            });
    };

    const filteredUsers = users.filter(user => {
        if (SearchName !== '') {
            return user.name.toLowerCase().includes(SearchName.toLowerCase());
        }
        if (SearchEmail !== '') {
            return user.email.toLowerCase().includes(SearchEmail.toLowerCase());
        }
        if (SearchRole !== '') {
            return user.role.toLowerCase().includes(SearchRole.toLowerCase());
        }
        return user;
    });

    const history = useHistory();
    const selectUser = (userUid) => {
        history.push(`/admin/user/${userUid}`);
    };

    const indexOfLastPost = currentPage * usersPerPage;
    const indexOfFirstPost = indexOfLastPost - usersPerPage;
    const currentUsers = filteredUsers.sort((a, b) => a.name.localeCompare(b.name)).slice(indexOfFirstPost, indexOfLastPost);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const displayBasedOnRole = (role) => {
        switch (role) {
            case 'ROLE_USER':
                return t('users.role_user');
            case 'ROLE_ADMIN':
                return t('users.role_admin');
            case 'ROLE_SUPER_ADMIN':
                return t('users.role_super_admin');
            default:
                return '';
        }
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title={t('users.users_list')} subtitle="management" className="text-sm-left mb-3" />
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between">
                        <FormGroup className="mb-0">
                            <FormInput placeholder={t('users.search_name')} onChange={(e) => setSearchName(e.target.value)} />
                        </FormGroup>
                        <FormGroup className="mb-0">
                            <FormInput placeholder={t('users.search_email')} onChange={(e) => setSearchEmail(e.target.value)} />
                        </FormGroup>
                        <FormGroup className="mb-0">
                            <FormInput placeholder={t('users.search_role')} onChange={(e) => setSearchRole(e.target.value)} />
                        </FormGroup>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <div className="border-bottom">
                            <Nav justified>
                                <NavItem>
                                    <NavLink className="text-dark">
                                        {t('users.name')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark">
                                        {t('users.email')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark">
                                        {t('users.role')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark">
                                        {t('users.last_login')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-dark">
                                        {t('users.actions')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <div>
                            {currentUsers.map(user => (
                                <div key={user.user_uid} className="d-flex align-items-center justify-content-between border-bottom py-2 px-3">
                                    <div className="user__name" onClick={() => selectUser(user.user_uid)}>{user.name}</div>
                                    <div className="user__email" onClick={() => selectUser(user.user_uid)}>{user.email}</div>
                                    <div className="user__role" onClick={() => selectUser(user.user_uid)}>{displayBasedOnRole(user.role)}</div>
                                    <div className="user__last-login" onClick={() => selectUser(user.user_uid)}>{user.last_login ? new Date(user.last_login).toLocaleString() : t('users.never')}</div>
                                    <ButtonGroup className="user__actions">
                                        <Button theme="white" onClick={() => getSingleUser(user.user_uid)}>{t('users.edit')}</Button>
                                        <Button theme="white" onClick={() => confirmDelete(user.user_uid)}>{t('users.delete')}</Button>
                                    </ButtonGroup>
                                </div>
                            ))}
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Pagination usersPerPage={usersPerPage} totalUsers={users.length} paginate={paginate} />
                </Col>
            </Row>
        </Container>
    );
};

export default UsersList;
