// src/components/Navbar/MainNavbar.js
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from 'shards-react';
import NavbarSearch from './NavbarSearch';
import NavbarNav from './NavbarNav/NavbarNav';
import NavbarToggle from './NavbarToggle';

const MainNavbar = ({ layout, stickyTop, toggleSidebar }) => {
  const classes = classNames(
    'main-navbar',
    'bg-white',
    stickyTop && 'sticky-top'
  );

  return (
    <div className={classes}>
      <Container className="p-0 d-flex align-items-center">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0 w-100">
          <div className="d-flex align-items-center">
            <NavbarToggle toggleSidebar={toggleSidebar} />
            <img
              id="main-logo"
              className="d-inline-block align-top ml-2"
              src={require("../../../images/Logo smart farm1.jpg")}
              alt="Smart Farm"
            />
          </div>
          <div className="ml-auto d-flex align-items-center">
            <NavbarSearch />
            <NavbarNav />
          </div>
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  layout: PropTypes.string,
  stickyTop: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
