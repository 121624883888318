// src/layouts/DefaultLayout.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import Sidebar from '../components/layout/MainSidebar/SideBar';

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Container fluid>
      {!noNavbar && <MainNavbar toggleSidebar={toggleSidebar} />}
      <Row noGutters>
        <Col
          className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}
          xs="auto"
        >
          <Sidebar isVisible={isSidebarOpen} />
        </Col>
        <Col
          className={`main-content-container ${isSidebarOpen ? 'open' : 'closed'}`}
          xs
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

DefaultLayout.propTypes = {
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
