// Import necessary libraries and components
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import api from '../../../api/api';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

// Define the Sidebar component
export default function Sidebar({ isVisible }) {
  const { t, i18n } = useTranslation();
  let offer = JSON.parse(localStorage.getItem("user")).offer_type;
  let role = JSON.parse(localStorage.getItem("user")).role;
  let commandOption = JSON.parse(localStorage.getItem("user")).has_command;
  const [data, setData] = useState([{ Fields: [] }]);
  const [allSimulations, setAllSimulations] = useState([]);

  useEffect(() => {
    // Fetch all simulations and fields data
    const getAllSimulations = async () => {
      try {
        await api.get('/simulation/get-simulations')
          .then(result => {
            let listSimulations = result.data.simulations;
            if (result.data.type === "success") {
              setAllSimulations(listSimulations);
            }
          }).catch(err => {
            console.log(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    const fetchData = async () => {
      const response = await api.get('/field/fields');
      const newData = await response.data;
      setData(newData.farms);
    };
    getAllSimulations();
    fetchData();
  }, []);

  // Initialize fields and sensors arrays
  let Fields = [];
  let Sensors = [];
  data.map(item => {
    let fields = item.fields;
    if (fields) {
      fields.map(itemfield => {
        Fields.push({
          title: itemfield.name,
          itemId: '/Fields/' + itemfield.uid,
          itemUid: itemfield.uid
        });
        let sensors = itemfield.sensors;
        if (sensors) {
          sensors.map(sensor => {
            Sensors.push({
              title: sensor.code,
              itemId: '/Sensors/' + sensor.code,
              itemUid: sensor.uid
            });
          });
        }
      });
    }
  });

  // Initialize simulations array
  let simulations = [];
  allSimulations.map(simulation => {
    simulations.push({
      title: simulation.name,
      itemId: '/Simulations/' + simulation.id,
    });
  });

  const history = useHistory();

  // Define navigation items for different sections
  const AddElementsSubNavItems = [
    {
      title: `${t('farms')}`,
      itemId: "/AddFarm"
    },
    {
      title: `${t('fields')}`,
      itemId: "/AddField"
    },
    {
      title: `${t('sensors')}`,
      itemId: "/AddSensor"
    },
    {
      title: `${t('Equipment')}`,
      itemId: "/AddEquipment"
    },
    {
      title: `${t('water_balance')}`,
      itemId: "/Bilan",
    },
  ];

  const ConfigsSubNavItems = [
    {
      title: `${t('Countries')}`,
      itemId: "/admin/configuration"
    },
    {
      title: `${t('Cities')}`,
      itemId: "/admin/configuration/cities"
    },
    {
      title: `${t('Crops')}`,
      itemId: "/admin/configuration/crops"
    },
    {
      title: `${t('Crops Varieties')}`,
      itemId: "/admin/configuration/cropsVariety"
    },
    {
      title: `${t('Soils')}`,
      itemId: "/admin/configuration/soils"
    },
    {
      title: `${t('Irrigations')}`,
      itemId: "/admin/configuration/irrigations"
    }
  ];

  const ManagementSubNavItems = [
    {
      title: `${t('users_manag')}`,
      itemId: "/admin/users",
      elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('users_manag')}></i>
    },
    {
      title: `${t('sensors_manag')}`,
      itemId: "/admin/sensors",
      elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('sensors_manag')}></i>
    },
    {
      title: `${t('fields_manag')}`,
      itemId: "/admin/fields",
      elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('fields_manag')}></i>
    },
    {
      title: `${t('commande_manag')}`,
      itemId: "/admin/commande",
      elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('commande_manag')}></i>
    },
  ];

  const AddElementsFreeSubNavItems = [
    {
      title: `${t('water_balance')}`,
      itemId: "/Bilan",
      elemBefore: () => <i className="material-icons" style={{ width: "20px" }} title={t('water_balance')}>&#xe798;</i>
    },
  ];

  // Define main navigation items
  let Items = [
    {
      title: `${t('dashboard')}`,
      itemId: "/",
      elemBefore: () => <i className="fas fa-desktop" style={{ width: "20px" }} title={t('dashboard')}></i>
    },
    {
      title: `${t('fields')}`,
      itemId: "/MFields",
      elemBefore: () => <i className="fas fa-tractor" style={{ width: "20px" }} title={t('fields')}></i>,
      subNav: Fields
    },
    {
      title: `${t('sensors')}`,
      itemId: "/MSensors",
      elemBefore: () => <i className="fas fa-satellite-dish" style={{ width: "20px" }} title={t('sensors')}></i>,
      subNav: Sensors
    },
    {
      title: `${t('weather_forecast')}`,
      itemId: "/Weather",
      elemBefore: () => <i className="fas fa-cloud" style={{ width: "20px" }} title={t('weather_forecast')}></i>
    },
    {
      title: `${t('Satellite Images')}`,
      itemId: "/satellite-images",
      elemBefore: () => <i className="fas fa-satellite" style={{ width: "20px" }} title={t('Satellite Images')}></i>
    },
    {
      title: `${t('calendar')}`,
      itemId: "/Calendar",
      elemBefore: () => <i className="fas fa-calendar-week" style={{ width: "20px" }} title={t('calendar')}></i>
    },
    {
      title: `${t('my_simulations')}`,
      itemId: "/MSimulations",
      elemBefore: () => <i className="material-icons" style={{ width: "20px" }} title={t('my_simulations')}>&#xf071;</i>,
      subNav: simulations
    },
    {
      title: `${t('fields_settings')}`,
      itemId: "/AddField",
      elemBefore: () => <i className="fa fa-cog" style={{ width: "20px" }} title={t('fields_settings')}></i>
    },
    {
      title: `${t('sensor_settings')}`,
      itemId: "/sensor-settings",
      elemBefore: () => <i className="fa fa-cog" style={{ width: "20px" }} title={t('sensor_settings')}></i>
    }
  ];

  // Add admin-specific items to the navigation
  const addToSidebar = () => {
    let role = JSON.parse(localStorage.getItem("user")).role;
    if (role === "ROLE_ADMIN") {
      Items.push(
        {
          title: `${t('Configuration')}`,
          itemId: '/Madmin/configuration',
          elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('Configuration')}></i>,
          subNav: ConfigsSubNavItems
        },
        {
          title: `${t('Management')}`,
          itemId: '/Madmin/Management',
          elemBefore: () => <i className="fas fa-edit" style={{ width: "20px" }} title={t('Management')}></i>,
          subNav: ManagementSubNavItems
        }
      );
    }
  };

  // Call the function to add admin-specific items
  addToSidebar();

  // Display items based on the offer type
  const displayByOffer = () => {
    if (offer === "1") {
      Items = [
        {
          title: `${t('dashboard')}`,
          itemId: "/Dashboard",
          elemBefore: () => <i className="fas fa-desktop" style={{ width: "20px" }} title={t('dashboard')}></i>
        },
        {
          title: `${t('add_element')}`,
          itemId: "/MAdd",
          elemBefore: () => <i className="fas fa-plus-circle" style={{ width: "20px" }} title={t('add_element')}></i>,
          subNav: AddElementsFreeSubNavItems
        },
        {
          title: `${t('my_simulations')}`,
          itemId: "/MSimulations",
          elemBefore: () => <i className="material-icons" style={{ width: "20px" }} title={t('my_simulations')}>&#xf071;</i>,
          subNav: simulations
        },
        {
          title: `${t('calendar')}`,
          itemId: "/Calendar",
          elemBefore: () => <i className="fas fa-calendar-week" style={{ width: "20px" }} title={t('calendar')}></i>
        },
        {
          title: `${t('weather_forecast')}`,
          itemId: "/Weather",
          elemBefore: () => <i className="fas fa-cloud" style={{ width: "20px" }} title={t('weather_forecast')}></i>
        }
      ];
    }
    return Items;
  };

  // Handle item selection in the navigation
  const handleOnSelect = ({ itemId }) => {
    history.push(itemId);
  };

  return (
    <div>
      <Navigation
        activeItemId="/"
        onSelect={handleOnSelect}
        items={displayByOffer().map(item => ({
          ...item,
          title: isVisible ? item.title : null,
          subNav: item.subNav
            ? item.subNav.map(subItem => ({
                ...subItem,
                title: isVisible ? subItem.title : null
              }))
            : null
        }))}
      />
    </div>
  );
}

Sidebar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};
